body {
    font-family: 'Baloo 2', cursive;
}
.horizontal-list > div {
    display: inline-block;
}

.hidden {
    display: none;
}
.manage td.MuiTableCell-root {
    padding: 1px 16px 1px 16px;
}
.manage.manage-a td.MuiTableCell-root {
    padding: 8px 16px 8px 16px;
}
.manage th.option {
    width: 120px;
}

/* dashboard */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
}

.makeStyles-format-2 {
    margin-bottom: 2px !important;
}
.arrow-disabled {
    opacity: 0.1;
    background: transparent;
}
.selected-action-card {
    background-color: #282c34;
    opacity: 0.5;
}

#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

#react-paginate li {
    display: inline-block;
}

a:link,
a:visited,
a:hover,
a:active {
    color: black;
    opacity: 0.7;
}

/* filter box */
.filter-box {
    padding: 6px;
    display: -webkit-box;
}
.filter-box .MuiFormControl-root div {
    display: inline;
}
select#select-department,
#select-room {
    display: inline;
    padding: 11px 14px;
}

/* sliding pane */
.sliding-pane-overlay {
    z-index: 12345;
}
