.timeline {
    width: 100%;
    height: 100%;
}

.timeline-vertical {
    border-left: 1px solid #282c34;
    height: 82%;
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    opacity: 0.1;
}

.timeline-horizontal {
    border-bottom: 1px solid #282c34;
    height: 9px;
    margin: auto;
    width: 80%;
    opacity: 0.2;
}

.timeline-start, .timeline-end {
    position: absolute;
    bottom: 25px;
    width: 90px;
    text-align:center
}

.timeline-end {
    top: 1px;
}

.timeline-content {
    position: absolute;
}

.timeline span {
    font-size: 9px;
}

.timeline-block {
    font-size: 10px;
    width: 90px;

    margin-top: 45%;
}